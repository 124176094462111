<template>
  <div>
    <section class="course-cateory-section programs-offer">
      <div class="container">
        <div class="category-cntr">
          <div class="circle-pattern">
            <img src="/assets/images/banner/circle-pattern.svg " alt="" />
          </div>
          <div class="cart-cntr">
            <div class="courses-in-cart" v-if="this.totalRecordInCart > 0">
              <div class="mt-2 cart-details-cntr">
                <h2 class="cart-head">Shopping Cart</h2>
                <div class="container">
                  <div class="row">
                    <div class="left-cntr">
                      <div class="cart-courses pb-2">{{ this.totalRecordInCart == 1 ? "Course" : "Courses" }} in Cart: {{ this.totalRecordInCart }}</div>
                      <div class="white-box mb-20" v-for="(product, index) in cart" :key="index">
                        <!-- @click="redirectToCourseDetail(product)" -->
                        <div class="col-5 col-md-4 img-cntr">
                          <img class="image-tile" :src="product.CourseImage" :key="product.CourseName" width="206" height="120" />
                        </div>
                        <!-- @click="redirectToCourseDetail(product)" -->
                        <div class="col col-7 col-md-8 desc-section pl-0">
                          <h4 class="course-title">
                            <small class="d-block course-group" v-if="product.BoardName">{{ product.CourseGroupName }} - {{ product.BoardName }}</small>
                            <small class="d-block course-group" v-else>{{ product.CourseGroupName }}</small>
                            <span class="desc-heading">{{ product.CourseName }}</span>
                          </h4>
                          <span class="d-block payment-plan-title">{{ product.SOSPaymentPackageTitle }}</span>
                          <span class="d-block payment-plan-title">{{ product.Title }} ({{product.BillingPeriodFrequency}} {{product.BillingPeriodName}})</span>

                          <div class="price-cntr">
                            <span class="course-price" v-if="product.AmountDisplay">{{ product.AmountDisplay.slice(0, -3) }}</span>

                            <span class="strike-through-price pl-1"
                              ><s v-if="product.AmountStrikethroughDisplay">{{ product.AmountStrikethroughDisplay.slice(0, -3) }}</s></span
                            >
                          </div>
                        </div>
                        <div class="close-cntr">
                          <i @click="openConfirmationModal(product.SOSId)" class="far fa-times"></i>
                        </div>
                      </div>

                      <!-- <div class="white-box">
                <div class="content-block" v-for="(product, index) in cart" :key="index">
                  <div class="subhead">{{ product.CourseName }}</div>
                <div class="course-amount">
                    <span class="amount">{{ product.AmountDisplay }}</span>
                    <span class="remove" @click="openConfirmationModal(product.SOSId)">
                      <span class="far fa-backspace"></span>
                    </span>
                  </div>
                </div>

                <div class="total">
                  <span class="total-amount">{{ toCurrency(this.totalAmount) }}</span>
                  <span class="subhead-total">Total</span>
                </div>
                <div class="text-center pt-4 pb-4">
                  <button :disabled="this.totalRecordInCart == 0 || isbtnDisabled" @click="checkout()" class="btn secondary-btn">
                    <loader id="checkoutLoaderId"><span class="spinner-border"></span></loader>
                    Checkout
                  </button>
                </div>
              </div> -->
                    </div>
                    <div class="right-cntr">
                      <div class="checkout-cntr">
                        <span class="d-block">Total</span>
                        <span class="total-price d-block mb-2" v-if="this.totalAmount">{{ toCurrency(totalAmount).toString().slice(0, -3) }}</span>
                        <span class="strike-through-price total-strike" v-if="isStrikeThroughPriceAvailable"
                          ><s v-if="strikeThroughTotalAmount">{{ toCurrency(this.strikeThroughTotalAmount).toString().slice(0, -3) }}</s></span
                        >
                        <div class="btn-cntr">
                          <!-- <router-link to="/class" class="btn secondary-btn">Checkout</router-link> -->
                          <button @click="checkout()" class="btn secondary-btn">
                            <loader id="checkoutLoaderId"><span class="spinner-border"></span></loader>Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="no-courses-in-cart" v-else>
              <div class="col-md-10 mx-auto mt-2">
                <h2 class="cart-head">Shopping Cart</h2>
                <div class="cart-courses pb-2">{{ this.totalRecordInCart == 0 ? "Course" : "Courses" }} in Cart: {{ this.totalRecordInCart }}</div>
                <div class="">
                  <div class="img-cntr">
                    <img src="/assets/images/banner/no-courses-avatar.svg" alt="keep shoping" width="200" height="200" class="mx-auto pb-4" />
                  </div>
                  <no-record-template v-if="this.totalRecordInCart == 0" message="Your cart is empty. Keep shopping to find a course!" showAvatar="false"></no-record-template>

                  <div class="d-flex justify-content-center">
                    <router-link to="/category" class="btn secondary-btn"> Keep Shopping </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="circle-pattern-2">
        <img src="/assets/images/banner/circle-pattern-2.svg " alt="" />
      </div>
    </section>
    <b-modal centered text id="confirmRemoveModal" title="" hide-footer>
      <div class="cart-popup">
        <div class="text-center">
          <img src="assets/images/banner/thumbs-down.svg" alt="moka00dsj" />
          <p class="modal-head-success">Are you sure, you want to remove this course?</p>
          <div class="button-bar-confirm">
            <button class="btn cart-btn" @click="$bvModal.hide('confirmRemoveModal')">Cancel</button>
            <button class="btn secondary-btn" @click="removeFromCart()">Remove</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<!-- <section class="cart-view">

    <div class="container">
      <div class="cart-container">
      <div class="head">Course Selected : {{ this.totalRecordInCart }}</div>
      <div class="white-box">
        <div
          class="content-block"
          v-for="(product, index) in cart"
          :key="index"
        >
          <div class="subhead">{{ product.CourseName }}</div>
          <div class="course-amount">
            <span class="amount">{{ product.AmountDisplay }}</span>
            <span class="remove" @click="openConfirmationModal(product.SOSId)">
              <span class="far fa-backspace"></span>
            </span>
          </div>
        </div>
        <no-record-template
          v-if="this.totalRecordInCart == 0"
          message="Your cart is empty. Keep shopping to find a course!"
          showAvatar="false"
        ></no-record-template>
        <div class="total">
          <span class="amount">{{ toCurrency(this.totalAmount) }}</span>
          <span class="subhead">TOTAL</span>
        </div>
        <div class="check-out">
          <button
            class="btn cart-btn"
            :disabled="this.totalRecordInCart == 0 || isbtnDisabled"
            @click="checkout()"
          >
            <loader id="checkoutLoaderId"><span class="spinner-border"></span></loader>
            <span class="check-out-btn">Checkout</span>
          </button>
        </div>
      </div>
    </div>
    </div>

   
  </section> -->

<script>
import { appConstants } from "../../common/constants";
import { mapGetters, mapActions } from "vuex";
import { cart as cartStoreKeys, checkout as checkoutStoreKeys } from "../../store/keys";
import cryptojs from "../../plugins/crypto";
export default {
  metaInfo() {
    return {
      title: "Cart | KIPS VIRTUAL",
      titleTemplate: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
  asyncData({ store, route }) {
    //return the Promise from the action
  },
  mounted() {},
  data() {
    return {
      selectedSOSId: null,
      isbtnDisabled: false,
    };
  },
  computed: {
    ...mapGetters(cartStoreKeys.namespace, cartStoreKeys.getters),
    ...mapGetters(checkoutStoreKeys.namespace, checkoutStoreKeys.getters),
  },
  methods: {
    ...mapActions(cartStoreKeys.namespace, cartStoreKeys.actions),
    ...mapActions(checkoutStoreKeys.namespace, checkoutStoreKeys.actions),
    openConfirmationModal(sosId) {
      this.selectedSOSId = sosId;
      this.$bvModal.show("confirmRemoveModal");
    },
    removeFromCart() {
      this.remove_FromCart({
        data: this.selectedSOSId,
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          this.selectedSOSId = null;
          this.$bvModal.hide("confirmRemoveModal");
          this.checkout_cart();
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },

    checkout() {
      this.isbtnDisabled = true;
      this.check_Out()
        .then(() => {
          this.isbtnDisabled = false;
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
      // this.checkout_cart().then((response) => {
      //   this.saveCart()
      //   //  this.$router.push({ name: "signUp" });
      //   // window.location.href = `${process.env.VUE_APP_PORTAL_URL}/invoice/checkOut/?cartId=${this.cartId}`;
      // });
    },
    redirectToCourseDetail(model) {
      if (model.ProgramCategoryId === appConstants.enums.programCategory.Class) {
        this.$router.push({
          name: "classDetail",
          params: {
            course: this.RemoveSpecialChars(model.CourseName),
            board: this.RemoveSpecialChars(model.BoardName),
            group: this.RemoveSpecialChars(model.CourseGroupName),
            sosId: cryptojs.UTF.encrypt(model.SOSId),
            boardId: cryptojs.UTF.encrypt(model.BoardId),
            courseGroupId: cryptojs.UTF.encrypt(model.CourseGroupId),
          },
        }).catch(() => {});
      } else {
        this.$router.push({
          name: "examDetail",
          params: {
            course: this.RemoveSpecialChars(model.CourseName),
            sosId: cryptojs.UTF.encrypt(model.SOSId),
            courseGroupId: cryptojs.UTF.encrypt(model.CourseGroupId),
          },
        }).catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.spinner-border {
  margin-right: 3px;
  width: 1rem;
  height: 1rem;
  margin-bottom: 0.2rem;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
